import React, { createContext, useState, useEffect } from 'react';
import questionnaireService from '../api/services/questionnaire';

const QuestionnaireContext = createContext();

const QuestionnaireProvider = ({ children }) => {
  const [questionnaire, setQuestionnaire] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadQuestionnaire()
  }, [])

  const loadQuestionnaire = ( token = sessionStorage.getItem('current_questionnaire_token') ) => {
    setIsLoading(true);

    questionnaireService.fetchQuestionnaireByToken({ token })
    .then((response) => {
      setQuestionnaire(response.data);
      setIsLoading(false);
    })
    .catch((error) => {
      setError(error.message);
      setIsLoading(false);
    });
  }

  const saveAnswer = ({ questionId, optionId }) => {
    setIsLoading(true);

    const saveAnswerPromise = questionnaireService.saveAnswer({ questionId, optionId });

    saveAnswerPromise
      .then((response) => {
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setIsLoading(false);
      });

    return saveAnswerPromise;
  }

  return (
    <QuestionnaireContext.Provider value={{ questionnaire, saveAnswer, loadQuestionnaire, isLoading, error }}>
      {children}
    </QuestionnaireContext.Provider>
  );
};

export { QuestionnaireContext, QuestionnaireProvider };