import {React, useEffect} from "react";
import { useTranslation } from 'react-i18next';

import like from '../../../assets/img/like.svg'

const SurveyThankYou = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.body.classList.add('page-survey', 'page-thankyou');
        return () => {
            document.body.classList.remove('page-survey', 'page-thankyou')
        }
    },[]);

    return (
        <div className="container-xl">
            <div className="bg-white rounded-3 p-4 pb-5 shadow container-encuenta">
                <div className="content-questions mx-auto text-primary pt-5">
                    <img src={like} className="mb-3"/>
                    <div dangerouslySetInnerHTML={{ __html: t('survey.thank_you.title') }}></div>

                    <p dangerouslySetInnerHTML={{ __html: t('survey.thank_you.subtitle') }}></p>
                </div>
            </div>
        </div>
    )
}

export default SurveyThankYou;