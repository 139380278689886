import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux';

import './assets/css/index.css';
import App from './views/App';
import reportWebVitals from './reportWebVitals';
import history from './core/history';
import { QuestionnaireProvider } from "./core/contexts/QuestionnaireContext";
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QuestionnaireProvider>
      <Router history={history} >
        <App />
      </Router>
    </QuestionnaireProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
