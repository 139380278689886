import React, { useEffect, useState, useContext } from "react";
import { Button } from "react-bootstrap";
import { Spinner, Form, Input, FormGroup, FormFeedback } from "reactstrap";
import { useTranslation } from 'react-i18next';

import { QuestionItem } from "./QuestionItem";
import { QuestionnaireContext } from "../../../core/contexts/QuestionnaireContext";

export const QuestionsList = ({ questions, hanndleFinishSurvey }) => {
    const { t } = useTranslation();
    const { saveAnswer, isLoading, error } = useContext(QuestionnaireContext)
    const [currentIndexQuestion, setCurrentIndexQuestion] = useState(0)
    const [nextQuestionEnabled, setNextQuestionEnabled] = useState(false)
    const [previousQuestionEnabled, setPreviousQuestionEnabled] = useState(false)
    const [currentAnswer, setCurrentAnswer] = useState(null)
    const [errorOnSave, setErrorOnSave] = useState(null)

    const nextQuestion = () => {
        saveAnswer({ questionId: questions[currentIndexQuestion].id, optionId: currentAnswer })
            .then(() => {
                setCurrentIndexQuestion(currentIndexQuestion + 1)
                setCurrentAnswer(null)
            })
            .catch((error) => {
                setErrorOnSave(t('survey.error.send_answer'))
            })
    }

    const previousQuestion = () => {
        setCurrentIndexQuestion(currentIndexQuestion - 1)
    }

    const updateStateNavigate = () => {
        if ((currentIndexQuestion === questions.length - 1)) {
            setNextQuestionEnabled(false)
        } else {
            setNextQuestionEnabled(true)
        }

        if (currentIndexQuestion === 0) {
            setPreviousQuestionEnabled(false)
        } else {
            setPreviousQuestionEnabled(true)
        }
    }

    const hanndleQuestionResponse = ({ target: { value } }) => {
        setCurrentAnswer(value);
    }

    const hanndleFinish = () => {
        saveAnswer({ questionId: questions[currentIndexQuestion].id, optionId: currentAnswer })
            .then(() => {
                hanndleFinishSurvey()
            })
            .catch((error) => {
                setErrorOnSave(t('survey.error.send_answer'))
            })
    }

    useEffect(() => {
        setCurrentAnswer(questions[currentIndexQuestion]?.answer?.option_id);
        updateStateNavigate()
    }, [currentIndexQuestion])

    useEffect(() => {
        updateStateNavigate()
    }, [])

    return (
        <div className="bg-white rounded-3 p-4 pb-5 shadow container-encuenta">
            <div className="content-questions mx-auto">
                <div className="float-end pasos"><p>{t('survey.pagination.part')}: <strong> {currentIndexQuestion + 1} </strong>  / {questions.length}</p></div>
                <div>
                    {<QuestionItem number={currentIndexQuestion + 1} question={questions[currentIndexQuestion]} currentAnswer={currentAnswer} hanndleResponse={hanndleQuestionResponse} readOnly={isLoading} />}

                    <div className="text-center">
                        {nextQuestionEnabled ?
                            <Button onClick={nextQuestion} className="mx-auto" disabled={!nextQuestionEnabled || !currentAnswer || isLoading} >
                                {isLoading ?
                                    <>
                                        <Spinner size="sm">
                                            Loading...
                                        </Spinner>
                                        <span>
                                            {' ' + t('survey.pagination.next')}
                                        </span>
                                    </>
                                    :
                                    t('survey.pagination.next')
                                }

                            </Button>
                            :
                            <Button onClick={hanndleFinish} className="mx-auto" disabled={nextQuestionEnabled || !currentAnswer || isLoading} >
                                {isLoading ?
                                    <>
                                        <Spinner size="sm">
                                            Loading...
                                        </Spinner>
                                        <span>
                                            {' '+t('survey.feedback.saving')}
                                        </span>
                                    </>
                                    :
                                    t('survey.save_button')
                                }
                            </Button>
                        }
                        
                        <FormGroup>
                            <Input invalid={errorOnSave} className="d-none" />
                            <FormFeedback>
                                {errorOnSave}
                            </FormFeedback>
                        </FormGroup>
                    </div>
                </div>
            </div>
        </div>
    )
}