import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import langES from "./resources/lang/es/lang";
import langPT from "./resources/lang/pt/lang";

// the translations
const resources = {
  es: {
    translation: langES
  },
  pt: {
    translation: langPT
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: sessionStorage.getItem('last_lenguage') ?? "es",
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;