import {React, useEffect, useContext} from "react";
import { useNavigate } from 'react-router-dom';
import { Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import logoIcono from "../../../assets/img/logo-icono.svg";
import { Routes } from "../../../routes";
import { QuestionnaireContext } from "../../../core/contexts/QuestionnaireContext";

const Presentation = () => {
    const { t } = useTranslation()
    const { questionnaire, isLoading, error } = useContext(QuestionnaireContext)

    document.body.classList.add('page-presentation');
    useEffect(() => {
        return () => {
            document.body.classList.remove('page-presentation')
        }
    },[]);

    const navigate = useNavigate();

    return (
        <div className="container-lg">
            <Row className="hero py-4">
                <Col lg={6}>
                    <img src={logoIcono} width={42} className="mb-2"/>
                    {questionnaire?.id && !isLoading && !error && <>
                        <h2 className="mb-5">{t('presentation.title')}</h2>
                        
                        <p className="mb-4" dangerouslySetInnerHTML={{ __html: 
                            t('presentation.message')
                                .replace('<EXTERNAL_NAME>', questionnaire?.external_name)
                                .replace('<BUSINESS_NAME>', questionnaire?.business_name)
                            }}>
                        </p>
                    
                        <Button variant="outline-primary" className="mt-3 px-4 rounded-pill" onClick={() => { navigate(Routes.Survey.path) }}> {t('presentation.survey_button')} </Button>
                    </>}
                </Col>
            </Row>
        </div>
    )
}

export default Presentation;