import React from "react";
import { Card, InputGroup, Input, Label, Form, FormGroup } from 'reactstrap';

export const QuestionItem = ({ number, question: { id, answer, text, minimum, maximum, options, validation_message }, currentAnswer, hanndleResponse, readOnly = false }) => {
    return (
        <div className="box-encuesta">
            <Form className="encuesta-pasos" onSubmit={(e) => { e.preventDefault() }}>
                <h2 className="mb-4 text-uppercase"><span className="numero">{number}</span> {text} </h2>
                {/* <p className="mb-5">{subtitle}</p> */}

                <div className="container-options mb-5">
                    {options?.length > 0 ?
                        <>
                            {options?.map((option, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {
                                            <FormGroup check className="border-bottom py-3">
                                                {true &&
                                                    <Input
                                                        id={`question-${id}-Item-${index}`}
                                                        type="radio"
                                                        name={`question-id-${id}`}
                                                        value={option.id}
                                                        onChange={hanndleResponse}
                                                        checked={option.id == currentAnswer}
                                                        disabled={readOnly}
                                                    />
                                                }
                                                <Label className="mb-0" for={`question-${id}-Item-${index}`}> {option?.title} </Label>
                                            </FormGroup>
                                        }
                                        {/* <Form.Feedback  > </Form.Feedback> */}
                                    </React.Fragment>
                                )
                            })}
                        </>

                        :

                        <FormGroup>
                            <Label for={`question-${id}`} > </Label>
                            <Input
                                id={`question-${id}`}
                                type='textarea'
                                name={`question-id-${id}`}
                                // defaultValue={value}
                                onChange={hanndleResponse}
                            />
                        </FormGroup>
                    }
                </div>

            </Form>
        </div>
    )
}