import { API_QUESTIONNAIRES, DEFAULT_HEADER_CONFIG } from "../../constants";
import { dispatch } from "../dispatch";

async function fetchQuestionnaireByToken({ token }) {
    try {
        const response = await dispatch({
            url: `${API_QUESTIONNAIRES}/${token}`
        })
        return response;

    } catch (error) {
        throw new Error('Error al validar el token');
    }
}

async function saveAnswer({ questionnaireId = sessionStorage.getItem('current_questionnaire_token'), questionId, optionId }) {
    try {
        const response = await dispatch({
            method: 'POST',
            url: `${API_QUESTIONNAIRES}/${questionnaireId}/item`,
            body: {
                item_id: questionId,
                option_id: optionId
            }
        })
        return response;

    } catch (error) {
        throw new Error('Error al validar guardar la respuesta.');
    }
}

export default {
    fetchQuestionnaireByToken,
    saveAnswer
}